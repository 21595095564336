<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <div>
          <h3 class="mb-0">
            Email OTP Verification
          </h3>
          <ValidationObserver ref="userActivationForm">
            <form class="mt-4">
              <div class="form-group">
                <ValidationProvider name="Activation code" rules="required" v-slot="{ errors }">
                  <input v-model="userActivationCode" type="text" class="form-control mb-0" id="exampleInputPassword1" placeholder="Enter OTP Here">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="d-inline-block w-100">
                <button class="btn btn-primary" type="button" v-if="resendEmailBtn" @click="userActivationEmail()">
                  Resend Email
                </button>
                <button type="button" @click="userActivate" class="btn btn-primary float-right">
                  Validate
                </button>
              </div>
              <span v-if="!resendEmailBtn" @click="userActivationEmail()">
                otp sent to {{userEmail}} <br/>You can request for otp after 10 secs
              </span>
              <div class="sign-info">
                <span class="dark-color d-inline-block line-height-2">Go back to <router-link :to="{ name: 'auth.login'}">Sign In</router-link></span>
              </div>
            </form>
          </ValidationObserver>
          <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
            <div v-html="toastMsg">
            </div>
          </b-toast>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
<script>
import { User } from "../FackApi/api/user.js"
import ApiResponse from "../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"

export default {
  name: "Activate",
  components: {
    ValidationProvider
  },
  data: () => ({
    showToast: false,
    toastMsg: null,
    toastVariant: "default",
    toastTitle: "Registration Response",
    userEmail: "",
    userId: null,
    userActivationCode: "",
    resendEmailBtn: false
  }),
  mounted () {
    this.showResendEmail()

    if (this.$route.query.code) {
      let code = JSON.parse(atob(this.$route.query.code))
      this.userEmail = code.user_email
      this.userId = code.user_id = 0 || code.user_id == "0" ? null : code.user_id
      this.userActivationCode = code.otp
    }
    else if (this.userData) {
      this.userEmail = this.userData.user_email
      this.show_user_email = true
    }
    else {
      this.$router.push("/login")
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * showResendEmail
     */
    showResendEmail () {
      setTimeout(() => {
        this.resendEmailBtn = true
      }, 10000)
    },
    /**
     * userActivationEmail
     */
    async userActivationEmail () {
      await User.userActivationEmail(this, this.userEmail)
      this.toastMsg = "Email Resent"
      this.showToast = true
      this.toastVariant = "success"
      this.resendEmailBtn = false
      this.showResendEmail()
    },
    /**
     * userActivate
     */
    async userActivate () {
      let valid = await this.$refs.userActivationForm.validate()
      if (!valid) {
        return
      }

      let userObj = {
        "user_email": this.userEmail,
        "user_id": this.userData && this.userData.user_id ? this.userData.user_id : this.userId,
        "activation_code": this.userActivationCode
      }
      let userActivateResp = await User.userActivate(this, userObj)
      await ApiResponse.responseMessageDisplay(this, userActivateResp)
      if (userActivateResp.resp_status) {
        // If user not found than set basic profile details and redirect to registartion page
        // let userProfileData = {
        //   user_email: this.userEmail,
        //   is_profile_complete: false
        // }
        this.$store.dispatch("User/setUserProfileAction", userActivateResp.resp_data.data)

        setTimeout(() => {
          this.$router.push("/quick-profile")
        }, 1000)
      }
    }
  }
}
</script>
